import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getOperationalGroups(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getOperationalGroups,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getUsersOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsersOperationalGroup,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function addOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.addOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}
export function deleteOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteOperationalGroup,
      method: 'delete',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function updateOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.updateOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function addUserToOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.addUserToOperationalGroup,
      method: 'post',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}

export function removeUserFromOperationalGroup(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.removeUserFromOperationalGroup,
      method: 'delete',
      data
    })
		  .then(async function (response) {
		    resolve(response)
		})
		.catch(function (error) {
		    reject(error)
		  });
	})
}