<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol col="12" xl="12">
            <CCard>
              <CCardHeader style="display: flex; justify-content: space-between">
                <div style="display:flex; gap: 20px;">
                    <span style="font-size: 20px"> Grupos Operacionales </span> 
                  
                    <CButton color="success" @click="createEditModal = !createEditModal; createOrEdit = 'create';">Crear Grupo <font-awesome-icon icon="plus" class="ml-2" /></CButton>
                </div>

                <!--                 <CButton
                  color="primary"
                  size="sm"
                  class="m-1"
                  @click="updateUser(false, $event)"
                >
                  Agregar
                </CButton> -->

                <p style="font-size: 18px">
                  <span style="font-weight: 500">Total:</span>
                  {{ totalGroups }}
                </p>
              </CCardHeader>
              <CCardBody>
<!--                 <div class="d-flex justify-content-between" style="height: 80px;">
                <div style="display: flex;gap: 16px;align-items: center;justify-content: space-between;">
                <CButton
                style="width: 15px;height: 21px;display: flex;justify-content: center;align-items: center;"
                @click="collapseFilter = !collapseFilter"
                color="primary"
                class="mb-2"
              >
                <font-awesome-icon
                  :icon="!collapseFilter ? 'plus' : 'minus'"
                  style="font-size: 12px"
                />
              </CButton>
                </div>

                  <div style="display: flex; align-items: center">
                    <CSpinner
                      color="primary"
                      v-if="loaderSearch"
                      size="sm"
                      class="mb-3 mr-2"
                    />
                    <font-awesome-icon icon="search" class="mb-3 mr-2" v-else />
                    <CInput v-model="search" placeholder="Buscar usuario" />
                  </div>

                </div> -->
                <CDataTable
                  v-show="!loading"
                  hover
                  striped
                  :items="groups"
                  :fields="fields"
                  :items-per-page="limit"
                >
                  <!--                   <template #telefono="data">
                    <td>{{data.item.info.telefono}}</td>
                  </template>
                  <template #direccion="data">
                    <td>{{data.item.info.direccion}}</td>
                  </template>
                  <template #cuadrante="data">
                    <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
                  </template> -->
                  <template #actions="data">
              <td class="py-2">
                <CButton
                  class="mr-2"
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="
                    setCurrentItem(data.item, 'createEdit');
                    createOrEdit = 'edit';
                  "
                >
                  <font-awesome-icon icon="user-edit" />
                </CButton>
                <CButton
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentItem(data.item, 'delete')"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </td>
                  </template>
                </CDataTable>
                <div style="width: 200px; margin: 0 auto">
                  <CPagination
                    :active-page.sync="page"
                    :pages="Math.ceil(totalGroups / limit)"
                    align="center"
                  />
                </div>
                <div
                  v-if="loading"
                  class="d-flex flex-column justify-content-center align-items-center"
                  style="min-height: 300px"
                >
                  <CSpinner style="width: 4rem; height: 4rem" color="danger" />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
              </CCardBody>
            </CCard>
          </CCol>


                    <!--     Modal crear editar -->
          <CModal
          :closeOnBackdrop="false"
            class="modal-md"
            :title="createOrEdit == 'create' ? 'Crear Grupo Operacional' : 'Editar Grupo Operacional'"
            color="primary"
            :show.sync="createEditModal"
          >
            <CRow>
              <CCol col="12">
                <CInput label="Nombre" v-model="currentGroup.name" />
                <CInput label="Descripción" v-model="currentGroup.description" />
              </CCol>

            <CCol col="12" v-if="createOrEdit == 'edit'">
              <h6>Usuarios</h6>
            <CCol sm="12" md="6" style="padding-left: 0;">
              <div class="d-flex">
                <CInput @keypress="searchLoader = true" class="input-search" style="margin-bottom:0 !important;" label="Buscar Usuarios" v-model="search"/>
                <CButton :disabled="!selectedUser.length || disableBtn" @click="addUserToOperationalGroup" style="height: 36px;margin-top: auto;" color="primary">Añadir</CButton>
              </div>
          <CListGroup v-if="!searchLoader" style="overflow-y: scroll;height: 186px;">
            <CListGroupItem @click="togglerUsersSelected(user.id)" :color="selectedUser.includes(user.id) ? 'dark' : null" href="#" v-for="user in optionsUser" :key="user.id">
              <p style="margin-bottom: 0;">{{user?.name}} - ({{user?.email}})</p>
            </CListGroupItem>
            <CListGroupItem v-if="noresults">
              <p>No se encontraron resultados</p>
            </CListGroupItem>
          </CListGroup>
          <div style="padding: 12px; height: 186px;" v-else>
            <div class="loader"></div>
          </div>
        </CCol>
          <CDataTable
            v-if="!loadingUsers"
            class="mt-2"
            hover
            striped
            :items="go_users"
            :fields="go_users_fields"
            index-columnS
          >
          <template #name="data">
            <td>{{data.item.user.name}}</td>
          </template>
          <template #email ="data">
            <td>{{data.item.user.email}}</td>
          </template>
          <template #action="data">
            <td>
              <div style="height: 100%; display:flex; align-items:center;">
                <CButton
                  v-c-tooltip="'Eliminar Usuario de Grupo Operacional'"
                  color="danger"
                  size="sm"
                  class="m-1"
                  @click="removeUserOG(data.item)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </CButton>
              </div>
            </td>
          </template>
        </CDataTable>

          <div
            v-if="loadingUsers"
            class="d-flex flex-column justify-content-center align-items-center"
            style="min-height: 300px"
            >
                  <CSpinner style="width: 4rem; height: 4rem" color="danger" />
                  <p class="text-secondary my-2">Cargando datos ...</p>
                </div>
            </CCol>
            </CRow>

            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="success"
                square
                :disabled="!currentGroup.name || !currentGroup.description"
                size="sm"
                @click="createOrEdit == 'create' ? createGroup() : updateGroup()"
              >
                Guardar
              </CButton>
              <CButton
                color="red"
                square
                size="sm"
                @click="() => (createEditModal = !createEditModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>

<!--                     Modall Eliminar -->
            <CModal
            :closeOnBackdrop="false"
            title="Eliminar Grupo Operacional"
            color="primary"
            :show.sync="deleteModal"
            size="sm"
            class="modal-delete"
          >
            ¿Seguro que quieres eliminar el Grupo Operacional
            <span style="font-weight: 800">{{ currentGroup.name }}</span
            >?
            <div slot="footer" class="w-100">
              <CButton
                class="mr-2"
                color="primary"
                variant="outline"
                square
                @click="deleteGroup()"
              >
                Eliminar
              </CButton>
              <CButton
                color="secondary"
                variant="outline"
                square
                @click="() => (deleteModal = !deleteModal)"
              >
                Cancelar
              </CButton>
            </div>
          </CModal>
          
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import { getOperationalGroups, addOperationalGroup, deleteOperationalGroup, updateOperationalGroup, getUsersOperationalGroup, addUserToOperationalGroup, removeUserFromOperationalGroup } from "../../api/grupos_operacionales";
import { getUsers } from "../../api/users";
import _ from "lodash/lodash";

export default {
components: { getOperationalGroups },
  data() {
    return {
      //tabla
      page: 1,
      limit: 10,
      totalGroups: 0,
      loading: true,
      groups: [],
      fields: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "description",
          label: "Descripción",
          _props: { scope: "col" },
        },
        {
          key: "actions",
          label: "Acciones",
          _props: { scope: "col" },
        },
      ],
      currentGroup: {
        name: '',
        description: ''
      },
      createEditModal: null,
      createOrEdit: 'create',
      deleteModal: null,
      go_users: [],
      go_users_fields: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "email",
          label: "Email",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      search: '',
      searchLoader: false,
      optionsUser: [],
      selectedUser: [],
      allIds: [],
      noresults: null,
      loadingUsers: false
    };
  },
  methods: {
    addUserToOperationalGroup() {
      const data = {user_id: this.selectedUser[0], operational_group_id: this.currentGroup.id}
        this.searchLoader = true;
        this.disableBtn = true
      addUserToOperationalGroup(data).then(res => {
        this.selectedUser = []
        this.search = ''
        this.optionsUser = []
        this.disableBtn = false
        this.searchLoader = false;
        this.getUsersOperationalGroup(this.currentGroup.id)
      }).catch(e => {
        console.log(e);
        this.disableBtn = false
        this.searchLoader = false;
        this.selectedUser = []
        this.optionsUser = []
        this.search = ''
      })
    },
    searchUsers() {
      if (this.search.length > 2) {
        const data = {search: this.search, page: 1, limit: 20};
      getUsers(data).then(res => {
        this.optionsUser = res.data.filter(user => 
          !user.operational_groups.some(group => group.operational_group_id === this.currentGroup.id)
        );
        this.searchLoader = false
        this.noresults = !res.data.length ? true : false
      })
      } else {
        this.searchLoader = false
      }
    },
    togglerUsersSelected(id) {
      this.selectedUser = [id];
    },
    getGroups() {
      this.loading = true;
      const data = {
        page: this.page,
        limit: this.limit,
      };
      getOperationalGroups(data).then((res) => {
          this.loading = false;
          this.totalGroups = res.operational_group_total;
          this.groups = res.operational_group;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUsersOperationalGroup(operational_group_id) {
 /*      this.loading = true; */
      this.loadingUsers = true

      const data = { operational_group_id };
      getUsersOperationalGroup(data).then((res) => {
        this.loadingUsers = false
          this.loading = false;
          this.go_users = res.users
        })
        .catch((e) => {
          console.log(e);
        });
    },
    createGroup() {
        const data = {...this.currentGroup}
        addOperationalGroup(data).then(res => {
        this.currentGroup = {
          name: '',
          description: ''
        }
            this.createEditModal = false
            this.getGroups()
        })
    },
    updateGroup() {
        const data = {
        name: this.currentGroup.name,
        description: this.currentGroup.description,
        operational_group_id: this.currentGroup.id,
        }
        updateOperationalGroup(data).then(res => {
            this.createEditModal = false
            this.getGroups()
        })
    },
    deleteGroup() {
        deleteOperationalGroup({ operational_group_id: this.currentGroup.id }).then((res) => {
            this.deleteModal = !this.deleteModal;
            this.getGroups();
        });
    },
    setCurrentItem(item, modal) {
      this.currentGroup = { ...item };
      this.getUsersOperationalGroup(item.id)

      //qué modal se abre
      modal == "createEdit" ? (this.createEditModal = !this.createEditModal) : (this.deleteModal = !this.deleteModal);
    },
    removeUserOG(user) {
      console.log(user);
      const data = {operational_group_id: this.currentGroup.id, user_id: user.user_id}
      removeUserFromOperationalGroup(data).then(res => {
        this.getUsersOperationalGroup(this.currentGroup.id)
      }).catch(e => {
        console.log(e);
      })
    }
  },
  mounted() {
/*     if (!this.$store.state.userData.permisos.permissions.edit_operational_groups) {
        this.$router.push('/')
    } */
    this.getGroups();
  },
  watch: {
    page() {
      this.getGroups();
    },
    createOrEdit(value) {
        if (value == 'create') {
            this.currentGroup = {
            name: '',
            description: ''
          }
        }
    },
    search: _.debounce(function (value) {
      this.searchUsers()
    }, 1000)
  },
};
</script>

<style lang="scss" scoped>
.badge-answered {
  display: flex;
  width: 100px;
  color: #fff;
  justify-content: center;
  border-radius: 15px;
  font-weight: 500;
  font-size: 10px;
  padding: 2px 0;
}
.loader {
  width: 25px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     #0064ED 96%,#97440000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
     #0064ED 96%,#aa310100) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
</style>
